.app {
  margin: 2em 1em;
}

/* ----- */

@media only screen and (min-width: 600px) {

  .app {
    margin: 4em 4em;
  }

}

@media only screen and (min-width: 768px) {

  .app {
    margin: 10vh 20vw;
  }

}