.navbar {
    margin-bottom: 2.5em;
    position: relative;
}

.navbar .navlink {
    text-align: left;
    margin-right: 1em;
}

/* ----- */

@media only screen and (min-width: 600px) {

    .navbar {
        margin-bottom: 4em;
    }

    .navbar .navlink {
        width: auto;
        margin-right: 1em;
        margin-bottom: 3em;
    }

}

@media only screen and (min-width: 768px) {

    .navbar .navlink {
        width: auto;
    }

}