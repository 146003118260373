.project {
    display: inline-block;
}

.project header {

}

.project h1 {

}

.project img {
    margin-bottom: 3em;
    max-width: 100%;
}

.project a {
    margin-right: 1em;
}

.project .live {
    display: inline-block;
    margin-bottom: 0.5em;
}

/* ----- */

@media only screen and (min-width: 600px) {

}

@media only screen and (min-width: 992px) {

    .project .content {
        flex-wrap: nowrap;
    }

}