.work p {
    /*font-size: 0.8em;*/
}

.work .workInfo {
    position: relative;
    padding-bottom: 3em;
}

.work .workInfo p {
    padding-bottom: 0.5em;
}

/* ----- */

@media only screen and (min-width: 600px) {

}

@media only screen and (min-width: 992px) {

}

@media only screen and (min-width: 1200px) {

}