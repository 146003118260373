.home main {
    display: flex;
    flex-wrap: wrap;
}

.home main p {
    width: 100%;
}

#socialLinks {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

#socialLinks span {
    flex: 1 1 auto;
}

#socialLinks a {
    text-decoration: none;
    margin-right: 1em;
}

/* ----- */

@media only screen and (min-width: 992px) {

}
